<template lang="">
    <div>
        <Bank/>
    </div>
</template>
<script>
export default {
    components:{
        Bank : () => import('@/components/manage/bank/Bank.vue')
    }
}
</script>
<style lang="">
    
</style>